import React, { useState, useEffect, useMemo } from 'react';
import Database from '../utils/Database';
import { useSelector } from 'react-redux';

function AddNewSkill(props) {
    const [skillName, setSkillName] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState({ hasError: false, message: '' });
    const [touchedForm, setTouchedForm] = useState(false);
    const [currentSkillNames, setCurrentSkillNames] = useState([]);
    const user = useSelector(state => state.user.user);

    async function addSkill(e) {
        e.preventDefault();
        setStatus('pending');

        const columnValues = { 
            skillName, 
            trackedTime: 0, 
            skillLevel: 1, 
            xp: 0, user 
        };

        try {
            const { data, error } = await Database.insertNewTime(columnValues);
            if (error) {
                setStatus('error');
                throw new Error('Something went wrong when inserting new time');
            } else {
                setStatus('success');
                props.fetchSkills();
            }
        } catch (error) {
            console.error(error.message);
        }

        setSkillName('');
        setTouchedForm(false);
        setError({ hasError: false, message: '' });

        setTimeout(() => setStatus('idle'), 500);
    }

    function handleChange(e) {
        const { value } = e.target;
        setSkillName(value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setTouchedForm(false);
        setError({ hasError: false, message: '' });
    }

    function handleInputBlur() {
        setStatus('idle');
        setTouchedForm(false);
        setError({ hasError: false, message: '' });
    }

    useEffect(() => {
        if (touchedForm) {
            if (skillName.trim().length > 20 || skillName.trim().length <= 0) {
                setFormIsValid(false);
                setError({ hasError: true, message: 'Your skill name must be greater than 0 characters and less than 20 characters long' });
            } else if (currentSkillNames.includes(skillName.trim().toUpperCase())) {
                setFormIsValid(false);
                setError({ hasError: true, message: `You already have the skill named [${skillName}]` });
            } else {
                setFormIsValid(true);
                setError({ hasError: false, message: '' });
            }
        }
    }, [skillName, touchedForm, currentSkillNames]);

    useEffect(() => {
        const skillNames = props.currentSkills.map(skill => {
            return skill.skill.toUpperCase();
        });
        setCurrentSkillNames(skillNames);
    }, [props.currentSkills])

    let statusDisplay;
    if (status === 'pending') {
        statusDisplay = <p className="input-hint">
            Loading...
        </p>;
    } else if (status === 'error') {
        statusDisplay = <p className="input-hint">
            Something went wrong, please try again
        </p>;
    } else if (status === 'success') {
        statusDisplay = <p className="input-hint success">
            Success!
        </p>;
    }

    if (error.hasError) {
        statusDisplay = <p className="input-hint">
            {error.message}
        </p>;
    }

    let inputStyle = '';
    if (!formIsValid && touchedForm) {
        inputStyle = 'invalid';
    }

    return (
        <form className='add-new-skill' onSubmit={handleSubmit}>
            <div className="input-label-container">
                <div className='label-container'>
                    <label htmlFor="add-skill">Add a new skill</label>
                </div>
                <div className='input-container'>
                    <input
                        className={inputStyle}
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                        onFocus={() => setTouchedForm(true)}
                        value={skillName}
                        type="text"
                        id="add-skill"
                    />
                </div>
                {statusDisplay}
            </div>
            <button disabled={!skillName || !formIsValid} onClick={addSkill}>+</button>
        </form>
    );
}

export default AddNewSkill;