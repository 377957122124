import React, { useEffect, useState } from 'react';
import AsyncModal from './AsyncModal';
import InputGroup from '../UI/forms/InputGroup';
import { useSelector } from 'react-redux';
import useDb from '../../hooks/useDb';

/**
 * CreateSkillModal
 */
function CreateSkillModal(props) {
    const [skillName, setSkillName] = useState('');
    const [difficulty, setDifficulty] = useState('easy');
    const user = useSelector(state => state.user.user);
    const isFirstLogin = useSelector(state => state.user.isFirstLogin);
    const { requestStatus, db } = useDb(props.close);

    /**
     * This will update the skill name.
     */
    const updateSkillName = (event) => {
        setSkillName(event.target.value);
    }

    /**
     * This will save the changes
     * to the database.
     */
    const saveChanges = (e) => {
        e.preventDefault();
        createSkill(e);
    }

    /**
     * This will create the skill.
     * 
     * @param {object} e 
     */
    async function createSkill(e) {
        e.preventDefault();

        const columnValues = { 
            skillName, 
            difficulty, 
            user
        };

        db('createSkill', columnValues, (data, error) => {
            if (error) {
                return 'There was an error while creating the skill.';
            }
            props.fetchSkills();
        });
    }

    /**
     * This will update the difficulty.
     */
    const updateDifficulty = (event) => {
        setDifficulty(event.target.value);
    }

    return (
        <AsyncModal
            {...props}
            title={'Create Skill'}
            buttonText={'Add Skill'}
            confirmAction={saveChanges}
            loading={requestStatus.loading}
            requestResult={requestStatus.success}
            error={requestStatus.error}
            displayStatus={true}
        >
            {isFirstLogin && <p className="margin-bottom-2 text-3">Add a skill name that represents what you're going to track. 
                Then you can set the difficulty. This will determine how long it takes to level up.</p>}
            <InputGroup 
                id='skillName' 
                label="Skill Name" 
                name="skillName" 
                value={skillName} 
                onChange={updateSkillName} 
            />
            <InputGroup 
                id='difficulty' 
                label="Difficulty" 
                name="difficulty" 
                type="select"
                options={[
                    { value: 'easy', text: 'Easy' },
                    { value: 'medium', text: 'Medium' },
                    { value: 'hard', text: 'Hard' },
                    { value: 'expert', text: 'Expert' }
                ]}
                value={difficulty} 
                onChange={updateDifficulty} 
            />
        </AsyncModal>
    );
}

export default CreateSkillModal;