import React from 'react';
import { H2 } from '../UI/headings';
import Button from '../UI/Button';
import getClassName from '../../utils/getClassName';

function AuthForm({
    title,
    subTitle,
    children,
    onSubmit,
    buttonText,
    helpText,
    loading,
    error,
    success,
    successText,
    ...props
}) {
    const classes = getClassName({className: props.className}, 'form-container');
    return (
        <React.Fragment>
            <form 
                className={classes} 
                onSubmit={(e) => onSubmit(e)}
            >
                <H2 className="form-title">{title}</H2>
                <p className="margin-bottom-3">{subTitle}</p>
                <div>
                    {error && <p className="error">{error}</p>}
                    {children}
                </div>
                {success && <p className="success">{successText ?? "Success!"}</p>}
                <div>
                    <Button
                        type="submit"
                        disabled={loading ?? props.disabled}
                    >
                        {buttonText}
                    </Button>
                </div>
            </form>
            {helpText}
        </React.Fragment>
    )
}

export default AuthForm;