import React, { useEffect, useState } from 'react';
import BasicPage from './page-types/BasicPage';
import { useParams, useNavigate } from 'react-router-dom';
import LeftArrow from '../../images/left-arrow-transparent.svg';
import RightArrow from '../../images/right-arrow-transparent.svg';
import { useSelector } from 'react-redux';
import ActivityList from '../activity/ActivityList.js';
import useDb from '../../hooks/useDb';
import Select from '../UI/forms/Select';
import { H2 } from '../UI/headings';
import DateHelper from '../../utils/DateHelper';

function ActivitiesPage(props) {
    const { skillId, timeframe } = useParams();
    const skills = useSelector(state => state.skill.allSkills);
    const [activities, setActivities] = useState([]);
    const [filters, setFilters] = useState({
        timeframe: timeframe ?? '',
        skill: skillId ?? 'all'
    });
    const user = useSelector(state => state.user.user);
    const { requestStatus, db } = useDb();
    const navigate = useNavigate();
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    let pageCount = Math.ceil(rowCount / limit);
    let page = offset / limit + 1

    /**
     * Handle navigation when filters change
     */
    useEffect(() => {
        let skillFilter = filters.skill;
        let timeframeFilter = filters.timeframe;

        if (!skillFilter && !timeframeFilter) {
            return;
        }

        let link = '/activities/' + skillFilter;
        if (timeframeFilter) {
            link += '/' + timeframeFilter;
        }

        navigate(link);

        setOffset(0);
    }, [filters]);

    /**
     * Fetch activities from the database.
     */
    useEffect(() => {
        if (!user?.id) {
            return;
        }

        getActivities();

        countRows();
    }, [skillId, user, offset, timeframe]);

    /**
     * This will get the activities from the database.
     *
     * @returns {void}
     */
    async function getActivities() {
        let method = 'getActivities';
        let id = user.id;

        if (skillId && skillId !== 'all') {
            method = 'getActivitiesForSkill';
            id = skillId;
        }

        if (!id) {
            return;
        }

        const params = [id, limit, offset];
        if (filters.timeframe && filters.timeframe !== 'all') {
            params.push(createTimeFrame());
        }

        db(method, params, (data, error) => {
            if (error) {
                return "There was an error getting the activities.";
            }
            setActivities(data);
        });
    }

    /**
     * This will count the rows in order
     * to calculate the pagination.
     */
    async function countRows() {
        let countMethod = (skillId && skillId !== 'all') ? 'countActivityLogForSkill' : 'countActivityLog';
        let params = [skillId];
        if (filters.timeframe && filters.timeframe !== 'all') {
            countMethod = 'countActivityLogForSkillTimeframe';
            if (skillId === 'all')
            {
                params = [];
                countMethod = 'countActivityLogTimeframe';
            }
            params.push(createTimeFrame());
        }

        db(countMethod, params, (data, error) => {
            if (error) {
                return "There was an error getting the activities count.";
            }

            setRowCount(data);
        });
    }

    /**
     * This will create a timeframe object
     *
     * @returns {object}
     */
    function createTimeFrame() {
        let start = new Date();
        let end = new Date();

        switch(filters.timeframe) {
            case 'today':
                start = DateHelper.formatToSqlDate(start);
                end = DateHelper.formatToSqlDate(end);
                break;
            case 'week':
                const day = start.getDate();
                start.setDate(day - 7);
                start = DateHelper.formatToSqlDate(start);
                end = DateHelper.formatToSqlDate(end);
                break;
            case 'month':
                const month = start.getMonth();
                start.setMonth(month - 1);
                start = DateHelper.formatToSqlDate(start);
                end = DateHelper.formatToSqlDate(end);
                break;
        }

        return { start, end };
    }

    /**
     * Fetch skills if empty.
     */
    useEffect(() => {
        if (!skills.length && user?.id) {
            props?.fetchSkills();
        }
    }, [user, skillId]);

    const skillOptions = skills.map(skill => {
        return {
            value: skill.id,
            text: skill.name
        };
    });
    return (
        <BasicPage
            prevLink="/"
            prevName="Dashboard"
        >
            <H2 className="margin-bottom-1">Activities</H2>
            <div className="filter-container">
                <Select
                    id='skill-filter'
                    name='skill-filter'
                    value={skillId || ''}
                    onChange={(event) => setFilters({ ...filters, skill: event.target.value })}
                    className='dark-select fit-select margin-right-4'
                    options={[
                        { value: 'all', text: 'All Skills' },
                        ...skillOptions
                    ]}
                 />
                <Select
                    id='activity-filter'
                    name='activity-filter'
                    value={filters.timeframe}
                    onChange={(event) => setFilters({ ...filters, timeframe: event.target.value })}
                    className='dark-select fit-select'
                    options={[
                        { value: '', text: 'Timeframe', disabled: true },
                        { value: 'all', text: 'All' },
                        { value: 'today', text: 'Today' },
                        { value: 'week', text: 'This Week' },
                        { value: 'month', text: 'This Month' }
                    ]}
                 />
            </div>
            <ActivityList
                showHeader={false}
                activities={activities}
            />
            <div className="pagination flex justify-around margin-top-3 align-center">
                <img
                    src={LeftArrow}
                    alt="Previous"
                    className="pagination-arrow"
                    onClick={() => page > 1 && setOffset(offset - limit)}
                />
                <div className="pagination-numbers flex">
                    {/* First page */}
                    {page > 1 && <span onClick={() => setOffset(0)}>
                        <span>
                            1
                        </span>
                        <span className='margin-right-3'>
                            ...
                        </span>
                    </span>}
                    {/* Current page */}
                    <div className='margin-right-3 color-accent'>
                        {page}
                    </div>
                    {/* Last page */}
                    {page < pageCount && <span onClick={() => setOffset((pageCount - 1) * limit)}>
                        <span>
                            ...
                        </span>
                        <span>
                            {pageCount}
                        </span>
                    </span>}
                </div>
                <img
                    src={RightArrow}
                    alt="Next"
                    className="pagination-arrow"
                    onClick={() => page < pageCount && setOffset(offset + limit)}
                />
            </div>
        </BasicPage>
    );
}

export default ActivitiesPage;