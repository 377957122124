import { useState } from 'react';
import { supabase } from '../../../supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import InputGroup from '../../UI/forms/InputGroup';
import AuthForm from '../../forms/AuthForm';
import AuthPage from '../page-types/AuthPage';

function SignUp() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateFields() === false) {
            return;
        }

        handleSignUp(e);
    }

    function validateFields() {
        if (password !== verifyPassword) {
            setError('Passwords do not match');
            return false;
        }
        if (password.length < 6) {
            setError('Password must be at least 6 characters');
            return false;
        }
        if (!username) {
            setError('Username is required');
            return false;
        }
        if (!email) {
            setError('email is required');
            return false;
        }
        if (!password) {
            setError('password is required');
            return false;
        }

        setError(null);
        return true;
    }

    async function handleSignUp(e) {
        setLoading(true);

        try {
            const { data, error } = await supabase.auth.signUp({
                email: email,
                password: password,
            });

            if (error) {
                throw error;
            } else {
                createProfile(e, data.user.id);
            }
        } catch (error) {
            console.error(error);
            setError("There was an error signing up.");
        } finally {
            setLoading(false);
        }
    }

    async function createProfile(e, userId) { 
        e.preventDefault();

        try {
            const { data, error } = await supabase
                .from('profiles') 
                .insert([
                    { 
                        id: userId,
                        username: username,
                        avatar_url: null
                    }
                ]);

            if (error) {
                throw error;
            } else {
                setSuccess(true);
                setError(null);
                setTimeout(() => {
                    navigate('/success');
                }, 1000);
            }
        } catch (error) {
            console.error(error);
            setError("There was an error creating your profile.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <AuthPage>
            <AuthForm
                title="Sign Up"
                onSubmit={(e) => handleSubmit(e)}
                buttonText={loading ? <span>Loading...</span> : <span>Sign Up</span>}
                helpText={<p>Already have an account? <Link to='/sign-in'>Sign in here!</Link></p>}
                loading={loading}
                error={error}
                success={success}
            >
                <InputGroup 
                        placeholder="Username"
                        required={true}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        name="username"
                        label="Username"
                    />
                    <InputGroup 
                        type="email"
                        placeholder="example@email.com"
                        required={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        label="Email"
                    />
                    <InputGroup 
                        type="password"
                        placeholder="Your password"
                        required={true}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        label="Password"
                    />
                    <InputGroup
                        type="password"
                        placeholder="Verify your password"
                        required={true}
                        value={verifyPassword}
                        onChange={(e) => setVerifyPassword(e.target.value)}
                        name="verifyPassword"
                        label="Verify Password"
                    />
            </AuthForm>
        </AuthPage>
    )
}

export default SignUp;