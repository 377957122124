import React, { useEffect, useState, useCallback } from 'react';
import ActivityList from './ActivityList';
import Database from '../../utils/Database';
import { useSelector } from 'react-redux';

/**
 * SpecifiedActivityTracker
 */
const SpecifiedActivityTracker = React.memo(function (props) {
    const [specifiedActivities, setSpecifiedActivities] = useState([]);
    const [weeklyActivitiesTime, setWeeklyActivitiesTime] = useState(null);
    const [dailyActivitiesTime, setDailyActivitiesTime] = useState(null);
    const skillLoading = useSelector(state => state.skill.loading);

    /**
     * This will calculate the total time
     * of a passed in array of activities.
     *
     * @param {array} activities
     * @returns {int}
     */
    const calculateTotalTime = useCallback((activities) => {
        let total = 0;
        for (let i = 0; i < activities.length; i++) {
            total += calculateTimeBetween(activities[i].start_time, activities[i].end_time);
        }
        return total;
    }, []);

    /**
     * This will get new activities each time
     * the skill changes and time is saved.
     */
    useEffect(() => {
        if (!props.skillId || skillLoading) {
            return;
        }

        /**
         * Get most recent 3 activities for the skill.
         */
        (async function () {
            const { data, error } = await Database.getActivitiesForSkill(props.skillId);
            if (error) {
                console.error(error);
                return;
            }

            setSpecifiedActivities(data);
        })();

        /**
         * Get weekly activities and calculate
         * weekly and daily time.
         */
        (async function () {
            const { data, error } = await Database.getWeeklyActivitiesForSkill(props.skillId);

            if (error) {
                console.error(error);
                return;
            }

            if (data.length === 0) {
                setWeeklyActivitiesTime(null);
                return;
            }

            /**
             * Setting weekly time.
             */
            const weeklyTotal = calculateTotalTime(data);
            setWeeklyActivitiesTime(weeklyTotal);

            /**
             * Setting the daily time.
             */
            const dailyActivities = data.filter(activity => {
                const activityDate = new Date(activity.start_time);
                const todaysDate = new Date();

                return activityDate.getDate() === todaysDate.getDate();
            });
            const dailyTotal = calculateTotalTime(dailyActivities);
            setDailyActivitiesTime(dailyTotal);
        })();

    }, [props.skillId, skillLoading, calculateTotalTime]);

    /**
     * This will calculate the time between a
     * start and end time for an activity.
     *
     * @param {string} start
     * @param {string} end
     */
    const calculateTimeBetween = (start, end) => {
        start = start.split('T').pop();
        start = start.substring(0, 5);
        end = end.split('T').pop();
        end = end.substring(0, 5);

        const startHour = start.split(':')[0];
        const startMinute = start.split(':')[1].substring(0, 2);
        const endHour = end.split(':')[0];
        const endMinute = end.split(':')[1].substring(0, 2);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;
        if (hours === 0 && minutes === 0) {
            return 0;
        }

        if (minutes < 0) {
            hours--;
            minutes = 60 - Math.abs(minutes);
        }

        return hours * 60 + minutes;
    }

    return (
        <ActivityList
            header={'Latest Activity'}
            activities={specifiedActivities}
            weeklyTime={weeklyActivitiesTime}
            dailyTime={dailyActivitiesTime}
            showTimeTotals={true}
            link={'/activities/' + props.skillId}
        />
    );
});

export default SpecifiedActivityTracker;