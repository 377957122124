import React from 'react';
import Button from './Button';

function SecondaryButton(props) {

    return (
        <Button 
            {...props} 
            className={'secondary-button'} 
            onClick={props.onClick} 
        >
            {props.children}
        </Button>
    );
}

export default SecondaryButton;