import react from 'react';

function Input(props) {
    return (
        <div className="input-container">
            <label htmlFor={props.name}>{props.label}</label>
            <input
                type={props.type ?? 'text'}
                name={props.name}
                id={props.name}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
            />
        </div>
    );
}

export default Input;