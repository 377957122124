import React from 'react';
import getClassName from '../../utils/getClassName';

function Icon(props) {
    let className = getClassName(props, 'icon');

    const size = (props.size ? props.size : 'small');
    switch (size) {
        case 'small':
            className += ' icon-1';
            break;
        case 'medium':
            className += ' icon-2';
            break;
        case 'large':
            className += ' icon-3';
            break;
        default:
            className += ' icon-1';
    }

    return (
        <img {...props} className={className} />
    );
}

export default Icon;