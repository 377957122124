import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LevelGauge from '../LevelGauge';
import TimerIcon from '../../images/timer-icon.svg';
import PlayIconFilled from '../../images/play-icon-filled.svg';
import PlayIconHollow from '../../images/play-icon-hollow.svg';
import Icon from '../UI/Icon';
import Button from '../UI/Button';
import { H4, H3 } from '../UI/headings';
import TimeHelper from '../../utils/TimeHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setSkill, startTimer, stopTimer } from '../../store/skillSlice';
import EditSkillModal from '../modals/EditSkillModal';
import DeleteSkillModal from '../modals/DeleteSkillModal';
import AddTimeModal from '../modals/AddTimeModal';
import LoadingSpinner from '../UI/LoadingSpinner';
import Dropdown from '../UI/Dropdown';

/**
 * SkillCard
 *
 * This renders a skill card
 * for the dashboard.
 */
function SkillCard(props) {
    const currentSkill = useSelector(state => state.skill);
    const skillIsLoading = useSelector(state => state.skill.isLoading);
    const isTracking = useSelector(state => state.skill.isTracking);
    const dispatch = useDispatch();
    const [isCurrent, setIsCurrent] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showTimeModal, setShowTimeModal] = useState(false);
    const navigate = useNavigate();

    const skill = props.skill ?? currentSkill;
    const skillName = skill.name;
    const totalTime = skill.totalTime;
    const level = skill.level;
    const xpNeeded = skill.xpNeeded;
    const xpOffset = skill.xpOffset;
    let xp = skill.xp;

    /**
     * Setting which skill is currently being tracked.
     */
    useEffect(() => {
        if (currentSkill.name === skillName || props.isCurrent) {
            setIsCurrent(true);
        } else {
            setIsCurrent(false);
        }
    }, [currentSkill.name]);

    /**
     * This will start the timer for the skill.
     */
    function callStartTimer()
    {
        dispatch(setSkill(skill));

        dispatch(startTimer());
    }

    /**
     * This will stop the timer for the skill.
     */
    function callStopTimer()
    {
        dispatch(stopTimer());
    }

    /**
     * This will open the delete modal.
     */
    function openDeleteModal()
    {
        setShowDeleteModal(true);
    }

    /**
     * This will close the delete modal.
     */
    function closeDeleteModal()
    {
        setShowDeleteModal(false);
    }

    /**
     * This will navigate to the skill page.
     * for a specific skill.
     */
    function toSkillPage()
    {
        // Setting the new current skill in state.
        dispatch(setSkill(skill));

        navigate(`/skill/${props.skill.id}`);
    }

    /**
     * Setting button text and callback.
     */
    const buttonCallback = (isCurrent && isTracking) ? callStopTimer : callStartTimer;
    const buttonText = (isCurrent && isTracking) ? 'Stop' : 'Start';

    /**
     * Setting xp to current xp if
     * the skill is currently being tracked.
     */
    xp = (isCurrent && isTracking) ? currentSkill.xp : xp;

    const isLarge = props.large === true;

    /**
     * Setting loading state.
     */
    let isLoading = (isCurrent && currentSkill.status === 'pending' || skillIsLoading && isCurrent);
    if (isLarge) {
        isLoading = skillIsLoading;
    }

    /**
     * Handling the button icon.
     */
    const buttonIcon = (isCurrent && isTracking) ? PlayIconFilled : PlayIconHollow;

    const SharedElements = (<>
        <Dropdown
                className='edit-dropdown'
                type='edit'
                options={[
                    {
                        text: 'Edit Skill',
                        callback: () => setShowEditModal(true)
                    },
                    {
                        text: 'Add Time',
                        callback: () => setShowTimeModal(true)
                    },
                    {
                        text: 'Delete Skill',
                        callback: openDeleteModal
                    }
                ]}
            />
            <AddTimeModal
                skill={skill}
                show={showTimeModal}
                close={() => setShowTimeModal(false)}
                fetchSkills={props.fetchSkills}
            />
            <EditSkillModal
                show={showEditModal}
                skill={skill}
                close={() => setShowEditModal(false)}
                fetchSkills={props.fetchSkills}
            />
            <DeleteSkillModal
                show={showDeleteModal}
                skill={skill}
                close={closeDeleteModal}
                fetchSkills={props.fetchSkills}
            />
    </>);

    const formattedTime = TimeHelper.formatTime(currentSkill.currentTime);
    const shouldDisplayTime = (isCurrent && isTracking);

    const StartButton = (<>
        {/* Button for start/stop */}
        {!isLoading && <Button className="start-button bg-primary" onClick={buttonCallback}>
            <Icon src={buttonIcon} alt="Play icon" size="medium" />
            &nbsp;{shouldDisplayTime ? formattedTime : buttonText}
        </Button>}
        {/* Button for loading state */}
        {isLoading && (
            <Button
                className="start-button bg-primary"
                disabled={true}
            >
                <LoadingSpinner size={'small'}/>
                &nbsp;Loading...
            </Button>
        )}
    </>);

    const SmallSkillCard = (<>
        <div className="skill-card padding-3 bg-grad-blue margin-bottom-2" key={skillName}>
                {SharedElements}
            <div className="left">
                <div onClick={toSkillPage}>
                    <H4 className="cursor-pointer" onClick={toSkillPage}>{skillName}</H4>
                    <p className="total-time heading-6">
                        <Icon src={TimerIcon} alt="Timer icon" size="small" />
                        &nbsp;{TimeHelper.formatTime(totalTime, true)}
                    </p>
                </div>
                {StartButton}
            </div>
            <div className="right">
                <LevelGauge level={level} xpNeeded={xpNeeded} xpOffset={xpOffset} xp={xp} />
            </div>
        </div>
    </>);

    const LargeSkillCard = (<>
        <div className="large-skill-card padding-3 margin-bottom-2" key={skillName}>
            <p className="total-time heading-6">
                <Icon src={TimerIcon} alt="Timer icon" size="small" />
                &nbsp;{TimeHelper.formatTime(totalTime, true)}
            </p>
            {SharedElements}
            <H3>{skillName}</H3>
            <LevelGauge level={level} xpNeeded={xpNeeded} xpOffset={xpOffset} xp={xp} />
            {StartButton}
        </div>
    </>);

    return (
        <>
            {!isLarge && SmallSkillCard}
            {isLarge && LargeSkillCard}
        </>
    );
}

export default SkillCard;