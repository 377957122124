import React from 'react';
import getClassName from '../../utils/getClassName';

function Button(props) {
    const className = getClassName(props, 'button');

    return (
        <button {...props} className={className} onClick={props.onClick} >
            {props.children}
        </button>
    );
}

export default Button;