import { useState, useEffect } from 'react';
import Database from '../utils/Database';

function useDb(resultCallback = null) {
    const [status, setStatus] = useState({
        error: null,
        success: null,
        loading: false
    });

    /**
     * This handles setting the
     * state for the save result.
     *
     * @param {bool} result
     */
    function handleResult(result) {
        setStatus((prev) => ({...prev, success: result}))
        if (result === false) {
            return;
        }

        setTimeout(() => {
            setStatus((prev) => ({...prev, success: null}));
            if (typeof resultCallback === 'function') {
                resultCallback();
            }
        }, 800);
    }

    /**
     * This will handle the database
     * requests.
     *
     * @param {string} method
     * @param {mixed} values
     * @param {function|null} dbCallback
     * @returns {void}
     */
    async function db(method, values, dbCallback = null) {
        setStatus((prev) => ({...prev, loading: true}));
        let errorMessage = 'Something went wrong with the database request';
        try {
            if (Array.isArray(values) === false) {
                values = [values];
            }
            const { data, error } = await Database[method](...values);
            if (typeof dbCallback === 'function') {
                errorMessage = dbCallback(data, error);
            }
            if (error) {
                handleResult(false);
                throw new Error(errorMessage);
            } else {
                handleResult(true);
            }
        } catch (error) {
            setStatus((prev) => ({...prev, error: error.message}));
        } finally {
            setStatus((prev) => ({...prev, loading: false}));
        }
    }

    return {
        requestStatus: status,
        db
    };
}

export default useDb;