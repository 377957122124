import React, { useEffect, useState, useCallback } from 'react';
import AddNewSkill from '../AddNewSkill';
import { supabase } from '../../supabaseClient';
import Skill from '../skill/Skill.js';
import { useDispatch, useSelector } from 'react-redux';
import { setSkillName } from '../../store/skillSlice';
import LoadingSpinner from '../UI/LoadingSpinner';
import Database from '../../utils/Database';


function SkillsPage() {
    const [skills, setSkills] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState(null);
    const [error, setError] = useState({ hasError: false, message: '' });
    const [status, setStatus] = useState('idle');
    const currentSkillName = useSelector(state => state.skill.skill);
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    const fetchSkills = useCallback(async () => {
        let currentUser = user;
        if (!currentUser) {
            const { data, error } = await supabase.auth.getSession();
            if (error) {
                console.error(error);
                return;
            } 
            
            currentUser = data.session.user;
        }

        setStatus('pending');
        try {
            const { data, error } = await Database.getAllSkills(currentUser);
            if (error) {
                setError({ hasError: true, message: error.message });
                setStatus('error');
                throw new Error('Something went wrong with getting all skills');
            } else {
                setSkills(data);
                if (data.length < 1) {
                    setStatus('not-found');
                } else if (data.length === 1) {
                    setStatus('idle');
                    dispatch(setSkillName(data[0].skill));
                    setSelectedSkill(user, data[0].skill);
                } else {
                    setStatus('idle');
                }
            }
        } catch (error) {
            console.error(error.message);
            setStatus('error');
        }
    }, [setSkills, setStatus, dispatch]);

    async function setSelectedSkill(user, skill) {
        try {
            const { data, error } = Database.setSelectedSkill(user, skill);
            if (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function handleSkillChange(e) {
        const { id } = e.target;

        dispatch(setSkillName(id));
        Database.setSelectedSkill(user, id);
    }

    async function handleDeleteSkill() {
        try {
            const { data, error } = await Database.deleteSkill(user, skillToDelete);
            if (error) {
                console.error(error.message);
                throw new Error('Something went wrong while deleting skill');
            } else {
                if (skillToDelete === currentSkillName) {
                    dispatch(setSkillName(skills[0].skill));
                    Database.setSelectedSkill(user, skills[0].skill);
                }

                if (skills.length <= 1) {
                    dispatch(setSkillName(null));
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowDeleteModal(false);
            fetchSkills();
        }
    }

    function displayDeleteModal(skill) {
        setSkillToDelete(skill);
        setShowDeleteModal(true);
    }

    function closeDeleteModal() {
        setShowDeleteModal(false);
    }

    useEffect(() => {
        fetchSkills();
    }, [fetchSkills]);

    const skillsList = skills.map((skill) => {
        let skillClasses = currentSkillName === skill.skill ? 'choose-skill-radio current-skill' : 'choose-skill-radio';
        return (
            <li key={skill.skill}>
                <Skill
                    displayDeleteModal={displayDeleteModal}
                    closeDeleteModal={closeDeleteModal}
                    handleSkillChange={handleSkillChange}
                    handleDeleteSkill={handleDeleteSkill}
                    showDeleteModal={showDeleteModal}
                    skillToDelete={skillToDelete}
                    skillClasses={skillClasses}
                    currentSkillName={currentSkillName}
                    skillName={skill.skill}
                    skillLevel={skill["skill_level"]}
                    timeTracked={skill["time_tracked"]}
                    xp={skill.xp}
                />
            </li>
        );
    });

    return (
        <div id="skills-page">
            <AddNewSkill fetchSkills={fetchSkills} currentSkills={skills} />
            <ul className="skills-container">
                {status === 'pending' && <LoadingSpinner />}
                {status === 'not-found' && <p style={{ color: '#fff', textAlign: 'center', padding: '20px' }}>No skills found. Add your first skill and start tracking!</p>}
                {skillsList}
            </ul>
        </div>
    );
}

export default SkillsPage;