import React, { useEffect, useState, useRef } from 'react';

/**
 * Degrees for the level gauge.
 */
const degAtZero = 45;
const degAtOneHundered = 225;
const degPerPercent = (degAtOneHundered - degAtZero) / 100;

function LevelGauge(props) {
    const [deg, setDeg] = useState(45);
    const barRef = useRef(null);

    /**
     * Rotating the level bar according
     * to the percentage to the next level.
     */
    useEffect(() => {
        let levelPercent = ((props.xp - props.xpOffset) / (props.xpNeeded - props.xpOffset)) * 100;
        if (levelPercent < 0) {
            levelPercent = 0;
        }
        const newDeg = degAtZero + (degPerPercent * levelPercent);
        
        if (newDeg > degAtOneHundered) {
            setDeg(degAtOneHundered);
            return;
        }

        setDeg(newDeg);

        barRef.current.style.transform = `rotate(${newDeg}deg)`;
    }, [props.xp, props.xpOffset, props.xpNeeded]);

    return (
        <div className='level-gauge'>
            <div className="level-bar">
                <div className="level-bar-fill" ref={barRef}></div>
            </div>
            <span className="last-xp color-primary-light text-3">{props.xpOffset}</span>
            <div className='level-container flex flex-column'>
                <span className="current-xp color-primary-light text-3">{props.xp}</span>
                <span className="heading-3 level">Level {props.level}</span>
            </div>
            <span className="next-xp color-primary-light text-3">{props.xpNeeded}</span>
        </div>
    );
}

export default LevelGauge;