import React from 'react';
import Logo from '../../../images/level-up-logo-2.svg';
import Ab1 from '../../../images/ab1.webp';
import Ab2 from '../../../images/ab2.webp';
import Ab3 from '../../../images/ab3.webp';
import Ab4 from '../../../images/ab4.webp';
import Ab5 from '../../../images/ab5.webp';
import Ab6 from '../../../images/ab6.webp';
import getClassName from '../../../utils/getClassName';

function AuthPage(props) {
    const classes = getClassName({className: props.className});
    return (
        <div id="auth-page" className={classes}>
            <div className="left-container">
                <img src={Logo} alt="Level Up Logo" />
                {props.children}
            </div>
            <div className='image-container'>
                {/* <img src={Ab2} /> */}
            </div>
        </div>
    )
}

export default AuthPage;