
export default class DateHelper {

    static formatToSqlDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    /**
     * This will get the start and end of the
     * week as Monday and Sunday.
     * 
     * @param {string} format
     * @returns {object}
     */
    static getStartAndEndOfWeek(format = 'sql') {
        const currentDate = new Date();
        const dayOfWeek = currentDate.getDay();
        
        /**
         * Calculate the difference to Monday (0 = Sunday, 1 = Monday, ...)
         */
        let diffToMonday = (dayOfWeek === 0) ? -6 : 1 - dayOfWeek;
        if (dayOfWeek === 1) {
            diffToMonday = 0;
        }

        /**
         * Start of the week (Monday)
         */
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() + diffToMonday);

        /**
         * End of the week (Sunday)
         */
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        let start = startOfWeek;
        let end = endOfWeek;
        
        /**
         * Format dates to YYYY-MM-DD
         */
        if (format === 'sql') {
            start = this.formatToSqlDate(startOfWeek);
            end = this.formatToSqlDate(endOfWeek);
        }

        return {
            start,
            end
        };
    }

    /**
     * This will get the day name
     * for a date.
     * 
     * @param {string} dateStr 
     * @returns {string}
     */
    static getDay(dateStr) {
        const dayArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Create a date object from the string
        const date = new Date(dateStr + 'T00:00:00');

        // Adjust the time to noon to avoid any issues with daylight saving time changes
        date.setHours(12);

        // Use toLocaleString with the appropriate options
        const day = dayArr[date.getDay()];

        return day;
    }

    /**
     * This will get dates
     * of the week for monday through friday.
     * 
     * @returns {object}
     */
    static mondayToSunday() {
        const currentDate = new Date();
        const dayOfWeek = currentDate.getDay();
        
        // Calculate the difference to Monday (0 = Sunday, 1 = Monday, ...)
        const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

        // Start of the week (Monday)
        const monday = new Date(currentDate);
        monday.setDate(currentDate.getDate() + diffToMonday);

        const tuesday = new Date(monday);
        tuesday.setDate(monday.getDate() + 1);

        const wednesday = new Date(tuesday);
        wednesday.setDate(tuesday.getDate() + 1);

        const thursday = new Date(wednesday);
        thursday.setDate(wednesday.getDate() + 1);

        const friday = new Date(thursday);
        friday.setDate(thursday.getDate() + 1);

        const saturday = new Date(friday);
        saturday.setDate(friday.getDate() + 1);

        // End of the week (Sunday)
        const sunday = new Date(saturday);
        sunday.setDate(saturday.getDate() + 1);

        return {
            monday: this.formatToSqlDate(monday),
            tuesday: this.formatToSqlDate(tuesday),
            wednesday: this.formatToSqlDate(wednesday),
            thursday: this.formatToSqlDate(thursday),
            friday: this.formatToSqlDate(friday),
            saturday: this.formatToSqlDate(saturday),
            sunday: this.formatToSqlDate(sunday)
        };
    } 
}