import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Sucess from './Sucess';
import ForgotPassword from './ForgotPassword';

function Auth() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path='/success' element={<Sucess />} />
                <Route path="*" element={<SignIn />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Auth;
