import React from 'react';
import Header from '../../UI/Header';
import Icon from '../../UI/Icon';
import LeftArrow from '../../../images/left-arrow.svg';
import getClassName from '../../../utils/getClassName';
import { useNavigate } from 'react-router-dom';

function BasicPage(props) {
    const className = getClassName(props, "page-container");
    const navigate = useNavigate();

    const goBack = () => {
        if (props.prevLink && typeof props.prevLink === 'string') {
            navigate(props.prevLink);
        } else {
            navigate(-1);
        }
    }

    const prevName = props.prevName ? props.prevName : 'Go back';
    return (
        <div>
            <Header />
            <div className={className}>
                {props.prevLink && <p onClick={goBack} className="prev-page-link margin-bottom-1">
                    <Icon src={LeftArrow} alt="Play icon" size="small" />
                    <span className="text-1 bold">{prevName}</span>
                </p>}
                {props.children}
            </div>
        </div>
    );
}

export default BasicPage;