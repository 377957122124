import React, { useEffect, useState } from 'react';
import Modal from '../UI/Modal';
import InputGroup from '../UI/forms/InputGroup';
import { useSelector } from 'react-redux';
import Database from '../../utils/Database';

/**
 * EditSkillModal
 */
function EditSkillModal(props) {
    const [skillName, setSkillName] = useState(props.skill.name);
    const [difficulty, setDifficulty] = useState(props.skill.difficulty);
    const user = useSelector(state => state.user.user); 

    /**
     * This will update the skill name.
     */
    const updateSkillName = (event) => {
        setSkillName(event.target.value);
    }

    /**
     * This will save the changes
     * to the database.
     * 
     * @param {object} e
     * @returns {void}
     */
    const saveChanges = (e) => {
        e.preventDefault();
        updateSkill(e);
    }

    /**
     * This will handle updating 
     * the skill in the database.
     * 
     * @param {object} e 
     * @returns {void}
     */
    async function updateSkill(e) {
        e.preventDefault();

        const columnValues = { 
            skillName, 
            difficulty, 
            skillId: props.skill.id,
            user
        };

        try {
            const { data, error } = await Database.updateSkill(columnValues);
            if (error) {
                throw new Error('Something went wrong while updating the skill.');
            } else {
                props.fetchSkills();
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    /**
     * This will update the difficulty.
     */
    const updateDifficulty = (event) => {
        setDifficulty(event.target.value);
    }

    return (
        <Modal
            {...props}
            title={'Edit ' + props.skill.name} 
            buttonText={'Save'}
            modalID={props.skillName}
            confirmAction={saveChanges}
        >
            <InputGroup 
                id='skillName' 
                label="Skill Name" 
                name="skillName" 
                value={skillName} 
                onChange={updateSkillName} 
            />
            <InputGroup 
                id='difficulty' 
                label="Difficulty" 
                name="difficulty" 
                type="select"
                options={[
                    { value: 'easy', text: 'Easy' },
                    { value: 'medium', text: 'Medium' },
                    { value: 'hard', text: 'Hard' },
                    { value: 'expert', text: 'Expert' }
                ]}
                value={difficulty} 
                onChange={updateDifficulty} 
            />
        </Modal>
    );
}

export default EditSkillModal;