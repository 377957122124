import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Input from '../forms/Input';
import { setUser } from '../../store/userSlice';

function Account({ session }) {
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState(null);
    const [avatar_url, setAvatarUrl] = useState(null);
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const redirect = useNavigate();

    useEffect(() => {
        if (!user) {
            dispatch(setUser(session.user));
        }
        getProfile(session.user);
    }, [session]);

    async function getProfile(user) {
        try {
            setLoading(true);

            let { data, error, status } = await supabase
                .from('profiles')
                .select(`username, avatar_url`)
                .eq('id', user.id)
                .single();

            if (error && status !== 406) {
                throw error;
            }

            if (data) {
                setUsername(data.username);
                setAvatarUrl(data.avatar_url);
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function updateProfile({ username, avatar_url }) {
        try {
            setLoading(true);

            const updates = {
                id: user.id,
                username,
                avatar_url,
                updated_at: new Date()
            }

            let { error } = await supabase.from('profiles').upsert(updates, {
                returning: 'minimal', //Don't return value after inserting
            });

            if (error) {
                throw error;
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    }

    function signOut() {
        supabase.auth.signOut();
        redirect("/", { replace: true });
    }

    return (
        <div className="profile-container">
            <div className="form-container">
                <Input
                    label="Email"
                    type="text"
                    value={session.user.email}
                    readOnly
                    disabled
                    id="email"
                />
                <Input
                    label="Username"
                    type="text"
                    value={username || ''}
                    id="username"
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Link className="link" to='/change-password'>Change Password</Link>
                <div className="form-buttons-container">
                    <button
                        onClick={() => updateProfile({ username, avatar_url })}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Update'}
                    </button>
                    <button
                        onClick={signOut} 
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Account;