import React from 'react';

function Select(props) {
    const options = props.options || [];

    return <select
        id={props.id} 
        name={props.name} 
        value={props.value} 
        onChange={props.onChange}
        className={props.className ?? ''}
    >
        {options.map((option, index) => {
            const optionProps = {
                value: option.value,
                disabled: option.disabled ?? false,
                key: index
            };
            return <option {...optionProps}>{option.text}</option>
        })}
    </select>
}

export default Select;