import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        profile: null,
        isFirstLogin: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            if (action.payload) {
                state.user.lastSignIn = action.payload["last_sign_in_at"];
            }
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
            if (action.payload) {
                state.isFirstLogin = action.payload["is_first_login"];
            }
        },
        setIsFirstLogin: (state, action) => {
            state.isFirstLogin = action.payload;
        },
    }
});

export const { setIsFirstLogin, setUser, setProfile } = userSlice.actions;
export default userSlice.reducer;