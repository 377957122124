
class TimeHelper {
    static makeTimestamp(date) {
        const seconds = date.getSeconds();
        const minutes = date.getMinutes();
        const hours = date.getHours();
        const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
        const timestamp = `${month + 1}-${day}-${year} ${hours}:${minutes}:${seconds}`;

        return timestamp;
    }

    static formatTime(time, labeled = false) {
        let hours = Math.floor((time / 60) / 60);
        let minutes = Math.floor(time / 60 % 60);
        let seconds = Math.floor(time % 60);

        if (labeled) {
            return `${hours}h ${minutes}m`;
        }

        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (hours < 10) {
            hours = '0' + hours;
        }

        return `${hours}:${minutes}:${seconds}`;
    }

    //  Parse date to be mm-dd-yyyy  //
    static parseDate(date) {
        const splitDate = date.split('-');
        const tempArray = date.split('-');
        splitDate[0] = tempArray[1];
        splitDate[1] = tempArray[2];
        splitDate[2] = tempArray[0];
        return splitDate.join('/');
    }

    static parseTimeStamp(time) {
        let parsedTime = time.split('T').pop();
        parsedTime = parsedTime.split(':');
        let am = false;

        if (parsedTime[0] >= 12) {
            am = false;
        } else {
            am = true;
        }
        if (parsedTime[0] > 12) {
            parsedTime[0] = parsedTime[0] - 12;
        }

        parsedTime.pop();
        parsedTime[1] += am ? 'am' : 'pm';
        parsedTime.join(':');
        return parsedTime.join(':');
    }

    static findTotalTime(start, end) {
        let totalTime = [];
        // Getting time from timestamp //
        start = start.split('T').pop();
        start = start.substring(0, 5);
        end = end.split('T').pop();
        end = end.substring(0, 5);

        const startHour = start.split(':')[0];
        const startMinute = start.split(':')[1].substring(0, 2);
        const endHour = end.split(':')[0];
        const endMinute = end.split(':')[1].substring(0, 2);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;
        if (minutes < 0) {
            hours--;
            minutes = 60 - Math.abs(minutes);
        }
        totalTime[0] = hours;
        totalTime[1] = minutes;

        totalTime[0] += 'h';
        totalTime[1] += 'm';

        return totalTime.join(' ');
    }
}

export default TimeHelper;