import React from 'react';
import Modal from '../UI/Modal';

function Skill(props) {
    return (
        <React.Fragment>
            <Modal
                title={'Warning'}
                body={<React.Fragment>
                    Are you sure you want to delete this skill? <span>{props.skillToDelete}</span> 
                    <br></br>All of your saved progress will be lost.
                </React.Fragment>}
                buttonText={'Delete Skill'}
                confirmAction={props.handleDeleteSkill}
                cancelAction={props.closeDeleteModal}
                show={{ showModal: props.showDeleteModal, id: props.skillToDelete }}
                className={'warning-modal'}
                modalID={props.skillName}
            />
            <div className="skill-tracker">
                <div className="skill">
                    {props.skillName}
                </div>
                <div className="level">
                    <span>lvl</span>
                    <span>{props.skillLevel}</span>
                </div>
            </div>
            <div className="btns-container">
                <label htmlFor={props.skillName} className={props.skillClasses}>
                    {props.currentSkillName !== props.skillName ? 'Choose skill' : 'Current Skill'}
                    <input
                        onChange={props.handleSkillChange}
                        type="radio"
                        id={props.skillName}
                        name="skill"
                        checked={props.skillName === props.currentSkillName}
                    />
                    <div className="checked-display"></div>
                </label>
                <button onClick={() => props.displayDeleteModal(props.skillName)} className='skill-delete-btn'>Delete</button>
            </div>
        </React.Fragment>
    );
}

export default Skill;