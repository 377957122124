import { configureStore } from '@reduxjs/toolkit';
import skillSliceReducer from './skillSlice.js';
import activitiesSliceReducer from './activitiesSlice.js';
import userSliceReducer from './userSlice.js';


export const store = configureStore({
   reducer: {
      skill: skillSliceReducer,
      activities: activitiesSliceReducer,
      user: userSliceReducer,
   },
});
