import React from 'react';

function LoadingSpinner(props) {
    const size = (props.size ? props.size : 'small');
    const className = (props.className ? props.className : '');
    const classes = 'loading-spinner ' + className + ' ' + size;

    return (
        <div className={classes}>
            <div></div>
        </div>
    );
}

export default LoadingSpinner;