import { useState, useEffect } from 'react';
import { supabase } from '../../../supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../forms/Input';
import AuthForm from '../../forms/AuthForm';
import AuthPage from '../page-types/AuthPage';
import InputGroup from '../../UI/forms/InputGroup';

import ReactGA from 'react-ga4';

function SignIn() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Sign In'
        });
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();

        setError(null);

        setLoading(true);

        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: email,
                password: password,
            });

            if (error) {
                throw error;
            } else {
                setLoading(false);
                navigate('/');
            }
        } catch (error) {
            setLoading(false);
            setError(error.error_description || error.message);
        }
    }

    return (
        <AuthPage>
            <AuthForm
                title="Log In"
                onSubmit={(e) => handleLogin(e)}
                buttonText={loading ? <span>Loading...</span> : <span>Log In</span>}
                helpText={<p>Don't have an account? <Link to='/sign-up'>Sign up here!</Link></p>}
                loading={loading}
                error={error}
            >
                <InputGroup
                    type="email"
                    placeholder="example@email.com"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    label="Email"
                />
                <InputGroup
                    type="password"
                    placeholder="Your password"
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    label="Password"
                />
                <Link to='/forgot-password'>Forgot password?</Link>
            </AuthForm>
        </AuthPage>
    )
}

export default SignIn;