import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import LightningBolt from '../../images/level-up-bolt.svg';
import UserIcon from '../../images/user-icon.svg';
import ProfileModal from '../modals/ProfileModal';

function Header(props) {
    const [showProfileModal, setShowProfileModal] = useState(false);

    return (
        <header className="main-header">
            <div className="header-content">
                <Link to='/'>
                    <h1 className="brand">
                        Level&nbsp;<span>Up&nbsp;</span>
                        <img src={LightningBolt}/>
                    </h1>
                </Link>
                <div>
                    <img 
                        className="user-icon cursor-pointer"
                        src={UserIcon} 
                        onClick={() => setShowProfileModal(true)}
                    />
                </div>
                <ProfileModal 
                    close={() => setShowProfileModal(false)} 
                    show={showProfileModal}
                />
            </div>
        </header>
    );
}

export default Header;