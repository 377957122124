import React, { useEffect, useState } from 'react';
import Modal from '../UI/Modal';
import { useSelector } from 'react-redux';
import Database from '../../utils/Database';

/**
 * DeleteSkillModal
 */
function DeleteSkillModal(props) {
    const user = useSelector(state => state.user.user); 
    const skill = props.skill;

    /**
     * This will handle deleting a skill.
     * 
     * @returns {void}
     */
    async function handleDeleteSkill() {
        try {
            const { data, error } = await Database.deleteSkill(user, skill.id);
            if (error) {
                throw new Error('Something went wrong while deleting the skill.');
            } 
        } catch (error) {
            console.error(error);
        } finally {
            props.fetchSkills();
        }
    }

    return (
        <Modal
            title={'Delete ' + skill.name + '?'}
            body={"Are you sure you want to delete this skill? All of your saved progress will be lost."}
            buttonText={'Delete Skill'}
            confirmAction={handleDeleteSkill}
            close={props.close}
            show={props.show}
            className={'warning-modal'}
            modalID={skill.name}
        />
    );
}

export default DeleteSkillModal;