import React from 'react';

function Input(props) {
    const type = props.type || 'text';

    return (
        <input {...props}  type={type} />
    );
}

export default Input;