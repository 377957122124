import { createSlice } from '@reduxjs/toolkit';
import { supabase } from '../supabaseClient';

const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        error: null,
        status: 'idle',
        recentActivities: [],
        hasActivity: false,
        playAnimation: false
    },
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setHasActivity: (state, action) => {
            state.hasActivity = action.payload;
        },
        loadActivities: (state, action) => {
            state.recentActivities = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setActivityAnimation: (state, action) => {
            state.playAnimation = action.payload;
        }
    }
});

export function playActivityAnimation() {
    return (dispatch, getState) => {
        let state = getState();
        dispatch(setActivityAnimation(true));

        const timeoutID = setTimeout(() => {
            dispatch(setActivityAnimation(false));
        }, 1000);
    }
}


export function getActivityData(user) {
    return async (dispatch, getState) => {

        if (!user) {
            return;
        }

        dispatch(setStatus('pending'));
        await supabase
            .from('activity_log')
            .select("*")
            .limit(3)
            .order('end_time', { ascending: false })
            .eq('user_id', `${user.id}`)
            .then(
                (response) => {
                    if (response.error) {
                        throw new Error(response.error.message);
                    }
                    if (response.data.length > 0) {
                        dispatch(loadActivities(response.data));
                    } else {
                        dispatch(setError("No activities found"));
                    }
                },
                (error) => {
                    console.error(error);
                    dispatch(setError(error.message))
                }
            )
            .catch((error) => {
                console.error(error);
                dispatch(setError(error.message));
            })
            .then(() => {
                dispatch(setStatus('idle'));
            });
    }
}

export function checkActivityLog(date, skillName, user) {
    return async (dispatch, getState) => {

        if (!user) {
            const { data, error } = await supabase.auth.getUser();
            if (!error) {
                user = data.user;
            }
        }

        const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

        dispatch(setStatus('pending'));

        await supabase
            .from('activity_log')
            .select("*")
            .limit(3)
            .eq('user_id', `${user.id}`)
            .eq('date', `${month + 1}/${day}/${year}`)
            .eq('skill_name', `${skillName}`)
            .order('date', { ascending: false })
            .then(
                (response) => {
                    if (response.error) {
                        throw response.error;
                    }
                    if (response.data.length > 0) {
                        dispatch(setHasActivity(true));
                    } else {
                        dispatch(setHasActivity(false));
                    }
                },
                (error) => {
                    console.error(error);
                    dispatch(setError(error.message))
                }
            )
            .catch((error) => {
                console.error(error);
                dispatch(setError(error.message));
            })
            .then(() => {
                dispatch(setStatus('idle'));
            });
    }
}

export const { setStatus, setHasActivity, loadActivities, setError, setActivityAnimation } = activitiesSlice.actions;
export default activitiesSlice.reducer;