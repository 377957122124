import React from 'react';
import Input from './Input';
import Select from './Select';

function InputGroup(props) {
    const input = <Input 
        {...props}
        id={props.id} 
        name={props.name} 
        type={props.type || 'text'} 
        value={props.value} 
        onChange={props.onChange}
    />;

    const options = props.options || [];
    const select = <Select 
        {...props}
        id={props.id} 
        name={props.name} 
        value={props.value} 
        onChange={props.onChange}
        options={options}
    />

    return (
        <div className='input-group'>
            <label htmlFor={props.id}>{props.label}</label>
            {props.type === 'select' ? select : input}
        </div>
    );
}

export default InputGroup;