/**
 * This is used to get the class
 * name for components.
 * 
 * @param {object} props
 * @param {string} defaultClass 
 * @returns {string}
 */
export default function getClassName(props, defaultClass = "") {
    return defaultClass + " " + (props.className ? props.className : '');
}