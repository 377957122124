import React from 'react';
import Modal from '../UI/Modal';

/**
 * AsyncModal
 */
function AsyncModal(props) {
    const error = props.error ?? 'There was an error saving the data.';
    return (
        <Modal
            {...props}
        >
            {props.children}
            {props.displayStatus && <div className='result-container'>
                {props.loading && <p>Loading...</p>}
                {props.requestResult === false && <p className="error">{error}</p>}
                {props.requestResult === true && <p className="success">Save Successful.</p>}
            </div>}
        </Modal>
    );
}

export default AsyncModal;