import { createSlice } from '@reduxjs/toolkit';
import { supabase } from '../supabaseClient';
import Database from '../utils/Database.js';
import { getActivityData } from './activitiesSlice';
import TimeHelper from '../utils/TimeHelper';

/**
 * Used while testing
 */
// const EASY_XP_START = 60; // 1 minutes in seconds

/**
 * Base xp to reach level 2
 * for each difficulty
 */
const EASY_XP_START = 900; // 15 minutes in seconds
const MEDIUM_XP_START = 1800; // 30 minutes in seconds
const HARD_XP_START = 2700; // 45 minutes in seconds
const EXPERT_XP_START = 3600; // 60 minutes in seconds

const baseXps = {
    'easy': EASY_XP_START,
    'medium': MEDIUM_XP_START,
    'hard': HARD_XP_START,
    'expert': EXPERT_XP_START
};

/**
 * Each diffuclty has a
 * different experience modifier
 * that makes it increasingly
 * more difficult to level up.
 */
const difficulties = {
    'easy': 1.042229,
    'medium': 1.0403101,
    'hard': 1.043335,
    'expert': 1.047616
};

/**
 * Approximate hours to level 100
 *
 * Easy: 1,500 hours
 * Medium: 2,500 hours
 * Hard: 5,000 hours
 * Expert: 10,000 hours
 */

/**
 * This will return the amount of xp needed to level up.
 *
 * @param {int} level
 * @param {string} difficulty
 * @returns {int}
 */
export function getXpNeeded(level, difficulty = 'easy') {
    let xp = baseXps[difficulty] * level;

    /**
     * Increasing xp needed exponentially
     */
    const exponentialFactor = difficulties[difficulty]; // exponential growth factor
    xp *= Math.pow(exponentialFactor, level - 1);

    return Math.round(xp);
}

/**
 * This will calculate what the xp was for the previous level
 * and set the offset to that value
 * so that each level essentially starts at zero.
 *
 * @param {int} level
 * @param {string} difficulty
 * @returns {int}
 */
export function getXpOffset(level, difficulty = 'easy') {
    const prevLevel = level - 1;
    if (prevLevel === 0) {
        return 0;
    }

    return getXpNeeded(prevLevel, difficulty);
}

const skillSlice = createSlice({
    name: 'skill',
    initialState: {
        level: 1,
        id: null,
        name: '',
        currentTime: 0, // Time currently tracked in seconds
        totalTime: 0, // Total time in seconds (saved in db)
        hasSkill: true,
        difficulty: 'easy',
        status: 'pending',
        error: null,
        xp: 0,
        xpNeeded: getXpNeeded(1),
        xpOffset: 0,
        isTracking: false,
        loading: false,
        allSkills: [] // This holds all of the user's skills
    },
    reducers: {
        setAllSkills: (state, action) => {
            state.allSkills = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsTracking: (state, action) => {
            state.isTracking = action.payload;
        },
        incrementLevel: (state) => {
            state.level += 1;
        },
        setSkillName: (state, action) => {
            state.name = action.payload;
        },
        setTotalTime: (state, action) => {
            state.totalTime = action.payload;
        },
        setCurrentTime: (state, action) => {
            state.currentTime = action.payload;
        },
        addTotalTime: (state, action) => {
            state.totalTime += action.payload;
        },
        addCurrentTime: (state, action) => {
            state.currentTime += action.payload;
        },
        loadStateFromDatabase: (state, action) => {
            state.level = action.payload["level"];
            state.name = action.payload.name;
            state.xp = action.payload.xp;
            const difficulty = action.payload.difficulty;
            state.difficulty = difficulty;
            state.xpNeeded = getXpNeeded(action.payload["level"], difficulty);
            state.xpOffset = getXpOffset(action.payload["level"], difficulty);
            state.totalTime = action.payload["time_tracked"];
            state.id = action.payload.id;
        },
        setHasSkill: (state, action) => {
            state.hasSkill = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        addXP: (state, action) => {
            state.xp += action.payload;
        },
        setXP: (state, action) => {
            let xp = (action.payload.xp + state.totalTime);
            state.xp = xp;
        },
        setXpNeeded: (state, action) => {
            state.xpNeeded = action.payload;
        },
        setXpOffset: (state, action) => {
            state.xpOffset = action.payload;
        },
        resetXP: (state) => {
            state.xp = 0;
        },
        resetXpOffset: (state) => {
            state.xpOffset = 0;
        },
        setSkill: (state, action) => {
            const { name, level, totalTime, xp, xpOffset, difficulty, id } = action.payload;
            state.name = name;
            state.level = level;
            state.totalTime = totalTime;
            state.xp = xp;
            state.xpOffset = xpOffset;
            state.difficulty = difficulty;
            state.id = id;
        }
    }
});

/**
 * Used to stop a timer
 * that's been started.
 */
let timerId = null;

/**
 * This will start the timer
 * for a skill.
 */
export function startTimer() {
    return async (dispatch, getState) => {
        const state = getState();
        const isTracking = state.skill.isTracking;
        if (isTracking) {
            return;
        }

        const start = Date.now();
        let timer = setInterval(() => {
            const milliseconds = Date.now() - start;
            const seconds = Math.floor(milliseconds / 1000);

            if ((milliseconds / 1000) >= 1) {
                dispatch(setCurrentTime(seconds));

                dispatch(xpThunk(seconds));
            }

        }, 500);

        timerId = timer;

        dispatch(setIsTracking(true));
    };
}

/**
 * This will stop the timer,
 * initiate updating the skill data, and
 * initiate inserting the activity log.
 *
 * @param {bool} saveData
 */
export function stopTimer(saveData = true) {
    return async (dispatch, getState) => {
        let state = getState();
        const isTracking = state.skill.isTracking;
        if (!isTracking) {
            return;
        }

        clearInterval(timerId);
        timerId = null;

        dispatch(setIsTracking(false));

        if (state.skill.hasSkill && saveData) {
            dispatch(updateTime());

            dispatch(insertActivity());
        }
    };
}

/**
 * This will update the time
 * for the skill in the db.
 */
export function updateTime() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));

        const state = getState();
        const skill = state.skill;

        const columnValues = {
            savedTime: skill.totalTime,
            trackedTime: skill.currentTime,
            skillLevel: skill.level,
            xp: skill.xp,
            skillName: skill.name,
            user: state.user.user
        };

        try {
            const { data, error } = await Database.updateTime(columnValues);
            if (error) {
                console.error(error.message);
                throw new Error('Somthing went wrong with updating the time.');
            }
        } catch (error) {
            console.error(error.message);
        }

        dispatch(resetTimer());

        dispatch(setLoading(false));
    };
}

/**
 * This will reset the current time
 * to 0 and get fresh skill data from
 * the db for the skill.
 */
export function resetTimer() {
    return async (dispatch, getState) => {
        let state = getState();
        const skillName = state.skill.name;
        const user = state.user.user;

        dispatch(setCurrentTime(0));

        dispatch(getSkillData(skillName, user));
    };
}

/**
 * This will insert a new activity log
 * for the skill.
 */
export function insertActivity() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));

        const state = getState();
        const skill = state.skill;
        const trackedTime = skill.currentTime;
        const user = state.user.user;
        const skillName = skill.name;
        const skillId = skill.id;

        // Subtracting time tracked in milliseconds from the current time to add the start time to the database //
        let startTime = new Date();
        const date = new Date();

        startTime.setTime(startTime - trackedTime * 1000);
        const startTimestamp = TimeHelper.makeTimestamp(startTime);
        const endTimestamp = TimeHelper.makeTimestamp(date);

        const columnValues = {
            date,
            startTimestamp,
            user,
            skillName,
            skillId,
            endTimestamp
        };

        try {
            const { data, error } = await Database.insertActivityLog(columnValues);
            if (error) {
                throw new Error('Something went wrong with inserting activity log');
            } else {
                dispatch(getActivityData(user));
            }
        } catch (error) {
            console.error(error.message);
        }

        dispatch(setLoading(false));
    };
}

/**
 * This takes the given xp, sets it to the state,
 * and checks if it is enough to level up.
 *
 * @param {int} xp
 * @returns
 */
export function xpThunk(xp) {
    return (dispatch, getState) => {
        let state = getState();

        const skillLevel = state.skill.level;
        const difficulty = state.skill.difficulty;

        dispatch(setXP({ xp: xp, xpOffset: state.skill.xpOffset }));
        const currentXP = state.skill.xp;

        const xpNeeded = getXpNeeded(skillLevel, difficulty);
        dispatch(setXpNeeded(xpNeeded));

        // Checking when to level up
        if (currentXP < xpNeeded) {
            return;
        }

        // Checks how many levels were gained while tracking
        let levelsGained = 1;
        let nextLevelXpNeeded = getXpNeeded(skillLevel + 1, difficulty);
        while (currentXP >= nextLevelXpNeeded) {
            levelsGained++;
            nextLevelXpNeeded = getXpNeeded(skillLevel + levelsGained, difficulty);
        }

        for (let i = 1; i <= levelsGained; i++) {
            dispatch(incrementLevel());
        }

        const newLevel = skillLevel + levelsGained;
        const newXpOffset = getXpOffset(newLevel, difficulty);
        dispatch(setXpOffset(newXpOffset));

        dispatch(setXP({ xp: xp, xpOffset: newXpOffset }));

        const newXpNeeded = getXpNeeded(newLevel, difficulty);
        dispatch(setXpNeeded(newXpNeeded));
    }
}

/**
 * This willl get data for a skill
 * from the db.
 *
 * @param {string} skillName
 * @param {object|null} user
 */
export function getSkillData(skillName, user) {
    return async (dispatch, getState) => {
        if (!user) {
            return;
        }

        dispatch(setStatus('pending'));
        await supabase
            .from('skills')
            .select("*")
            .eq('name', `${skillName}`)
            .eq('user_id', `${user.id}`)
            .then(
                (response) => {
                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    if (!response.data.length) {
                        dispatch(setError("Skill not found in database"));
                        dispatch(setHasSkill(false));
                    }

                    dispatch(setHasSkill(true));
                    const responseData = response.data[0];

                    dispatch(loadStateFromDatabase(responseData));
                },
                (error) => {
                    dispatch(setError(error.message));
                }
            ).catch((error) => {
                dispatch(setError(error.message));
            }).then(() => {
                dispatch(setStatus('idle'));
            });
    }
}

export const {
    setAllSkills,
    incrementLevel,
    setSkillName,
    loadStateFromDatabase,
    setTotalTime,
    addTotalTime,
    addCurrentTime,
    setCurrentTime,
    setLoading,
    setHasSkill,
    setError,
    setStatus,
    addXP,
    setXpNeeded,
    resetXP,
    setXP,
    setXpOffset,
    resetXpOffset,
    setSkill,
    setIsTracking } = skillSlice.actions;
export default skillSlice.reducer;