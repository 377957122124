import React, { useEffect, useState } from 'react';
import InputGroup from '../UI/forms/InputGroup';
import { useSelector } from 'react-redux';
import { supabase } from '../../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import AsyncModal from './AsyncModal';
import useDb from '../../hooks/useDb';

/**
 * ProfileModal
 */
function ProfileModal(props) {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [displayRequestStatus, setDisplayRequestStatus] = useState(true);
    const user = useSelector(state => state.user.user);
    const redirect = useNavigate();
    const { requestStatus, db } = useDb();

    /**
     * Handling getting/setting the
     * profile data.
     */
    useEffect(() => {
        /**
         * Only get the user profile when needed.
         */
        if (!user || email || username || !props.show) {
            return;
        }

        setEmail(user.email);
        getProfile();
    }, [user, email, username, props.show]);

    /**
     * This will get the users profile.
     */
    async function getProfile() {
        setDisplayRequestStatus(false);
        db('getProfile', user, (data, error) => {
            if (error) {
                return 'There was an error updating your profile.';
            } else if (data) {
                setUsername(data.username); 
            }
        }); 
    }

    /**
     * This will update users email.
     */
    const updateEmail = (event) => {
        setEmail(event.target.value);
    }

    /**
     * This will update the Username.
     */
    const updateUsername = (event) => {
        setUsername(event.target.value);
    }

    /**
     * This will update the user profile.
     */
    async function updateProfile() {  
        setDisplayRequestStatus(true);
        const updates = {
            id: user.id,
            username,
            avatar_url: ''
        }
        db('updateProfile', updates, (data, error) => {
            if (error) {
                return 'There was an error updating your profile.';
            }
        });
    }

    /**
     * This will sign out the user 
     * and redirect them to the home page.
     */
    async function signOut(e) {
        e.preventDefault();

        supabase.auth.signOut();
        redirect("/", { replace: true });
    }

    return (
        <AsyncModal
            {...props}
            title="Profile"
            buttonText={'Update'}
            modalID={user?.id}
            confirmAction={updateProfile}
            secondaryButton={true}
            secondaryButtonText={'Sign out'}
            secondaryButtonClick={signOut}
            link="/reset-password"
            linkText="Change Password"
            loading={requestStatus.loading}
            error={requestStatus.error}
            requestResult={requestStatus.success}
            displayStatus={displayRequestStatus}
        >
            <InputGroup 
                id='email' 
                label="Email" 
                disabled={true}
                name="email" 
                value={email} 
                onChange={updateEmail} 
            />
            <InputGroup 
                id='username' 
                label="Username" 
                name="username" 
                value={username} 
                onChange={updateUsername} 
            />
        </AsyncModal>
    );
}

export default ProfileModal;