import React, { useState, useMemo } from 'react';
import { H2 } from '../UI/headings';
import ActivityCard from './ActivityCard';
import { Link } from 'react-router-dom';
import Select from '../UI/forms/Select';

/**
 * ActivityList
 */
const ActivityList = React.memo(function (props) {
    const todaysDate = useMemo(() => new Date(), []);
    const [filters, setFilters] = useState({
        timeframe: '',
        skill: null
    });
    const activities = props.activities;

    /**
     * This will create the list of
     * activity cards.
     */
    let prevDate = null;
    const activitiesList = activities.map((activity, index) => {
        let { date, start_time: startTime, end_time: endTime, skill_name: skillName, id } = activity;

        const [month, day, year] = [todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getFullYear()];
        let todaysDateParsed = month < 10 ? `0${month + 1}/${day}/${year}` : `${month + 1}/${day}/${year}`;
        if (day < 10 && month < 10) {
            todaysDateParsed = `0${month + 1}/0${day}/${year}`;
        } else if (day < 10 && month > 10) {
            todaysDateParsed = `${month + 1}/0${day}/${year}`;
        }

        const totalTime = findTotalTime(startTime, endTime);
        startTime = parseTimeStamp(startTime);
        endTime = parseTimeStamp(endTime);

        const parsedStoredDate = parseDate(date);
        let displayDate = parsedStoredDate;

        if (todaysDateParsed === parsedStoredDate) {
            displayDate = 'Today';
        }

        const shouldDisplayDate = (prevDate !== displayDate);
        prevDate = displayDate;
        const reactsSpecialKeyItNeeds = skillName + id + endTime + index;
        return (
            <div key={reactsSpecialKeyItNeeds}>
                {shouldDisplayDate && <p className="text-1 activity-date bold">{displayDate}</p>}
                <ActivityCard
                    activity={skillName}
                    date={displayDate}
                    totalTime={totalTime}
                    timeRange={`${startTime} - ${endTime}`}
                    key={reactsSpecialKeyItNeeds}
                />
            </div>
        );
    });

    /**
     * This will format the date to be mm-dd-yyyy
     *
     * @param {string} date
     * @returns {string}
     */
    function parseDate(date) {
        const splitDate = date.split('-');
        const tempArray = date.split('-');
        splitDate[0] = tempArray[1];
        splitDate[1] = tempArray[2];
        splitDate[2] = tempArray[0];
        return splitDate.join('/');
    }

    /**
     * This will format the time to be hh:mm am/pm
     *
     * @param {string} time
     * @returns {string}
     */
    function parseTimeStamp(time) {
        let parsedTime = time.split('T').pop();
        parsedTime = parsedTime.split(':');
        let am = false;

        if (parsedTime[0] >= 12) {
            am = false;
        } else {
            am = true;
        }
        if (parsedTime[0] > 12) {
            parsedTime[0] = parsedTime[0] - 12;
        }

        parsedTime.pop();
        parsedTime[1] += am ? 'am' : 'pm';
        parsedTime.join(':');
        return parsedTime.join(':');
    }

    /**
     * This will calculate the total time
     * for the activity.
     *
     * @param {string} start
     * @param {string} end
     * @returns {string}
     */
    function findTotalTime(start, end) {
        let totalTime = [];

        start = start.split('T').pop();
        start = start.substring(0, 5);
        end = end.split('T').pop();
        end = end.substring(0, 5);

        const startHour = start.split(':')[0];
        const startMinute = start.split(':')[1].substring(0, 2);
        const endHour = end.split(':')[0];
        const endMinute = end.split(':')[1].substring(0, 2);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;
        if (minutes < 0) {
            hours--;
            minutes = 60 - Math.abs(minutes);
        }
        totalTime[0] = hours;
        totalTime[1] = minutes;

        totalTime[0] += 'h';
        totalTime[1] += 'm';

        return totalTime.join(' ');
    }

    const getTimeString = (time) => {
        if (!time) {
            return '0h 0m';
        }

        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return `${hours}h ${minutes}m`;
    };

    const header = props.header || 'Latest Activity';
    const showHeader = props.showHeader !== undefined ? props.showHeader : true;
    return (
        <div className="activity-tracker">
            <div className="heading-container">
                <div>
                    {showHeader && <H2>{header}</H2>}
                    <div className="flex">
                        {props.showTimeTotals && (
                            <p className='text-2 margin-top-5 margin-right-5'>
                                <span className="bold">This week:</span>&nbsp;
                                <span className="color-accent">{getTimeString(props.weeklyTime)}</span>
                            </p>
                        )}
                        {props.showTimeTotals && (
                            <p className='text-2 margin-top-5'>
                                <span className="bold">Today:</span>&nbsp;
                                <span className="color-accent">{getTimeString(props.dailyTime)}</span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {props.fullPage && <div className="filter-container">
                <Select
                    id='activity-filter'
                    name='activity-filter'
                    value={filters.timeframe}
                    onChange={(event) => setFilters({ ...filters, timeframe: event.target.value })}
                    className='dark-select fit-select'
                    options={[
                        { value: '', text: 'Timeframe', disabled: true },
                        { value: 'all', text: 'All' },
                        { value: 'today', text: 'Today' },
                        { value: 'week', text: 'This Week' },
                        { value: 'month', text: 'This Month' }
                    ]}
                 />
            </div>}
            <div className="activity-container bg-ele padding-4 border-radius-2">
                {activities.length > 0 && activitiesList}
                {activities.length === 0 && <p>Your activities will appear here.</p>}
                {props.link && <div className='flex justify-end'>
                    <Link to={props.link} className='view-all-link text-3'>View More</Link>
                </div>}
            </div>
        </div>
    );
});

export default ActivityList;