import React, { useState, useEffect } from 'react';
import { H3 } from './headings';
import Button from './Button';
import SecondaryButton from './SecondaryButton';
import Icon from './Icon';
import X from '../../images/x.svg';
import { Link } from 'react-router-dom';


/**
 * Modal
 */
function Modal(props) {
    /**
     * Setting classes for the modal.
     */
    let classes = 'modal';
    if (props.className) {
        classes += " " + props.className;
    }

    /**
     * Handling the show/hide of the modal.
     */
    let styles = {};
    if (props.show === true) {
        styles = { display: 'block' };
    } else {
        styles = { display: 'none' };
    }

    /**
     * This will handle the confirm action.
     * 
     * @param {object} e
     * @returns {void}
     */
    function handleConfirm(e) {
        if (typeof props.confirmAction === 'function') {
            props.confirmAction(e);
        }
    }

    const hasSecondary = props.secondaryButton ? true : false;
    const btnFlex = hasSecondary ? 'space-between' : 'justify-end';

    return (
        <>
            <div style={styles} className="modal-background" onClick={props.close}></div>
            <div className={classes} style={styles}>
                <header>
                    <H3>{props.title}</H3>
                    {!props.hideClose && <Icon className={'close-button cursor-pointer'} src={X} onClick={props.close} size={'small'} />}
                </header>
                {props.body && <p className='text-2'>{props.body}</p>}
                <div className="modal-content">
                    {props.children}
                </div>
                <footer>
                    <div className={"button-container " + btnFlex}>
                        {props.secondaryButton && 
                            <SecondaryButton 
                                onClick={props.secondaryButtonClick}
                            >
                                {props.secondaryButtonText}
                            </SecondaryButton>
                        }
                        <Button onClick={handleConfirm}>{props.buttonText}</Button>
                    </div>
                    {props.link && <Link className="link" to={props.link}>{props.linkText}</Link>}
                </footer>
            </div>
        </>
    )
}

export default Modal;