import { supabase } from '../supabaseClient';
import DateHelper from './DateHelper';

/**
 * Database
 */
export default class Database {

    /**
     * This will filter the result to only include
     * distinct values for the given key.
     *
     * @param {array} arr
     * @param {string} key
     * @returns {array}
     */
    static getDistinct(arr, key) {
        const unique = new Set();
        return arr.filter(item => {
            const isDuplicate = unique.has(item[key]);
            unique.add(item[key]);
            return !isDuplicate;
        });
    }

    /**
     * This will get all activity logs between
     * Monday and Sunday of this week.
     *
     * @param {object} user
     * @returns {object}
     */
    static async getDaysTrackedThisWeek(user) {
        const week = DateHelper.getStartAndEndOfWeek();

        const { data, error } = await supabase
            .from('activity_log')
            .select('date, start_time, end_time')
            .eq('user_id', `${user.id}`)
            .gte('date', `${week.start}`)
            .lte('date', `${week.end}`);

        if (error) {
            return { data: [], error };
        }

        return { data: data, error };
    }

    /**
     * This will get all activity logs between
     * Monday and Sunday of this week for a particular
     * skill.
     *
     * @param {int} skillId
     * @returns {object}
     */
    static async getWeeklyActivitiesForSkill(skillId) {
        const week = DateHelper.getStartAndEndOfWeek();

        const response = await supabase
            .from('activity_log')
            .select('*')
            .gte('date', `${week.start}`)
            .lte('date', `${week.end}`)
            .eq('skill_id', `${skillId}`)
            .order('start_time', { ascending: true });

        return response;
    }

    /**
     * This will insert a new skill in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async insertNewTime(columnValues) {
        const {
            skillName,
            trackedTime,
            skillLevel,
            xp,
            user,
        } = columnValues;
        const difficulty = columnValues.difficulty ?? 'easy';

        const { data, error } = await supabase
            .from('skills')
            .insert([
                {
                    name: `${skillName}`,
                    time_tracked: `${trackedTime}`,
                    user_id: `${user.id}`,
                    level: `${skillLevel}`,
                    xp: `${xp}`,
                    difficulty: `${difficulty}`
                }
            ]);

        return { data, error };
    }

    /**
     * This will create a new skill in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async createSkill(columnValues) {
        columnValues = { ...columnValues, trackedTime: 0, skillLevel: 1, xp: 0};
        return this.insertNewTime(columnValues);
    }

    /**
     * This will update an existing skill in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async updateSkill(columnValues) {
        const {
            skillName,
            skillId,
            difficulty,
            user
        } = columnValues;

        const date = new Date();
        const { data, error } = await supabase
            .from('skills')
            .update([
                {
                    name: `${skillName}`,
                    difficulty: `${difficulty}`,
                    updated_at: date.toISOString()
                }
            ])
            .eq('user_id', `${user.id}`)
            .eq('id', `${skillId}`);

        return { data, error };
    }

    /**
     * This will update the time for a skill in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async updateTime(columnValues) {
        const {
            savedTime,
            trackedTime,
            skillLevel,
            xp,
            skillName,
            user
        } = columnValues;

        const date = new Date();
        const { data, error } = await supabase
            .from('skills')
            .update({
                time_tracked: `${savedTime + trackedTime}`,
                level: `${skillLevel}`,
                xp: `${xp}`,
                updated_at: date.toISOString()
            })
            .eq('name', `${skillName}`)
            .eq('user_id', `${user.id}`);

        return { data, error };
    }

    /**
     * This will insert a new activity log in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async insertActivityLog(columnValues) {
        const {
            date,
            startTimestamp,
            user,
            skillName,
            endTimestamp,
            skillId
        } = columnValues;

        const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

        const { data, error } = await supabase
            .from('activity_log')
            .insert({
                start_time: `${startTimestamp}`,
                end_time: `${endTimestamp}`,
                date: `${month + 1}/${day}/${year}`,
                skill_name: `${skillName}`,
                user_id: `${user.id}`,
                skill_id: `${skillId}`
            });

        return { data, error };
    }

    /**
     * This will update an existing activity log in the database.
     *
     * @param {object} columnValues
     * @returns {object}
     */
    static async updateActivityLog(columnValues) {
        const {
            date,
            user,
            skillName,
            endTimestamp
        } = columnValues;

        const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

        const { data, error } = await supabase
            .from('activity_log')
            .update({ end_time: `${endTimestamp}` })
            .eq('user_id', `${user.id}`)
            .eq('skill_name', `${skillName}`)
            .eq('date', `${month + 1}/${day}/${year}`);

        return { data, error };
    }

    /**
     * This will get activities for a certain skill.
     *
     * @param {int} skillId
     * @param {int} limit
     * @param {int} offset
     * @param {object|null} timeframe
     * @returns {object}
     */
    static async getActivitiesForSkill(skillId, limit = 3, offset = 0, timeframe = null)
    {
        if (timeframe) {
            return await supabase
                .from('activity_log')
                .select("*")
                .limit(limit)
                .order('end_time', { ascending: false })
                .range(offset, offset + limit - 1)
                .eq('skill_id', `${skillId}`)
                .gte('date', `${timeframe.start}`)
                .lte('date', `${timeframe.end}`);
        }

        return await supabase
            .from('activity_log')
            .select("*")
            .limit(limit)
            .order('end_time', { ascending: false })
            .range(offset, offset + limit - 1)
            .eq('skill_id', `${skillId}`);
    }

    /**
     * This will get activities for a user.
     *
     * @param {int} userId
     * @param {int} limit
     * @param {int} offset
     * @param {object|null} timeframe
     * @returns {object}
     */
    static async getActivities(userId, limit = 3, offset = 0, timeframe = null)
    {
        if (timeframe) {
            return await supabase
                .from('activity_log')
                .select("*")
                .limit(limit)
                .order('end_time', { ascending: false })
                .range(offset, offset + limit - 1)
                .eq('user_id', `${userId}`)
                .gte('date', `${timeframe.start}`)
                .lte('date', `${timeframe.end}`);
        }

        return await supabase
            .from('activity_log')
            .select("*")
            .limit(limit)
            .order('end_time', { ascending: false })
            .range(offset, offset + limit - 1)
            .eq('user_id', `${userId}`);
    }

    /**
     * This will count activity rows for a skill id.
     *
     * @param {int} skillId
     * @returns {object}
     */
    static async countActivityLogForSkill(skillId)
    {
        const { data, error } = await supabase
            .rpc('count_activity_log_for_skill_2', { skillid: skillId });

        return { data, error };
    }

    /**
     * This will count activity rows for a skill id with a timeframe.
     *
     * @param {int} skillId
     * @param {object} timeframe
     * @returns {object}
     */
    static async countActivityLogForSkillTimeframe(skillId, timeframe)
    {
        return await supabase
            .rpc(
                'count_activity_log_for_skill_timeframe',
                {
                    skillid: skillId,
                    datestart: timeframe.start,
                    dateend: timeframe.end
                }
            );
    }

    /**
     * This will count activity rows with a timeframe.
     *
     * @param {object} timeframe
     * @returns {object}
     */
    static async countActivityLogTimeframe(timeframe)
    {
        return await supabase
            .rpc(
                'count_activity_log_timeframe',
                {
                    datestart: timeframe.start,
                    dateend: timeframe.end
                }
            );
    }

    /**
     * This will count activity rows for a skill id.
     *
     * @returns {object}
     */
    static async countActivityLog()
    {
        return this.countRows('activity_log');
    }

    /**
     * This will count rows for a table.
     *
     * @param {string} tableName
     * @returns {object}
     */
    static async countRows(tableName)
    {
        return await supabase
            .rpc('count_rows', { table_name: tableName});
    }

    /**
     * This will get all skills for a user.
     *
     * @param {object} user
     * @returns {object}
     */
    static async getAllSkills(user) {
        const { data, error } = await supabase
            .from('skills')
            .select('*')
            .order('updated_at', { ascending: false })
            .eq('user_id', `${user.id}`);

        return { data, error };
    }

    /**
     * This will get a skill.
     *
     * @param {object} user
     * @param {string} skillName
     * @returns {object}
     */
    static async getSkillByName(user, skillName) {
        const { data, error } = await supabase
            .from('skills')
            .select('*')
            .eq('user_id', `${user.id}`)
            .eq('name', `${skillName}`);

        return { data, error };
    }

    static async getSkillById(user, id) {
        const { data, error } = await supabase
            .from('skills')
            .select('*')
            .eq('user_id', `${user.id}`)
            .eq('id', `${id}`);

        return { data, error };
    }

    /**
     * This will delete a skill by id.
     *
     * @param {object} user
     * @param {int|string} skillId
     * @returns {object}
     */
    static async deleteSkill(user, skillId) {
        const { data, error } = await supabase
            .from('skills')
            .delete()
            .eq('user_id', `${user.id}`)
            .eq('id', `${skillId}`);

        if (error) {
            throw error;
        }

        return { data, error };
    }

    /**
     * This will get the selected skill.
     */
    static async getSelectedSkill(user) {
        const { data, error } = await supabase
            .from('skills')
            .select('name')
            .eq('user_id', `${user.id}`)
            .eq('selected', true);

        return { data, error };
    }

    /**
     * This will set the selected skill.
     *
     * @param {object} user
     * @param {string} skillName
     * @returns {object}
     */
    static async setSelectedSkill(user, skillName) {
        // First set all skills to unselected aside from skillName
        try {
            await this.setUnselectedSkills(user, skillName);
        } catch (error) {
            console.error(error);
            throw error;
        }

        // Set the selected skill
        const { data, error } = await supabase
            .from('skills')
            .update({ selected: true })
            .eq('user_id', `${user.id}`)
            .eq('name', `${skillName}`);

        return { data, error };
    }

    /**
     * This will update the user profile.
     *
     * @param {object} data
     * @returns {object}
     */
    static async updateProfile(data) {
        const date = new Date();
        const updates = {
            id: data.id,
            username: data.username,
            avatar_url: data.avatar_url,
            updated_at: date.toISOString()
        };

        return await supabase.from('profiles').upsert(updates, {
            returning: 'minimal', //Don't return value after inserting
        });
    }

    /**
     * This will update the user profile first login
     * to be false.
     *
     * @param {number} profileId
     * @returns {object}
     */
    static async updateFirstLogin(profileId) {
        const date = new Date();
        const updates = {
            id: profileId,
            is_first_login: false
        };

        return await supabase.from('profiles').upsert(updates, {
            returning: 'minimal', //Don't return value after inserting
        });
    }

    /**
     * This will get the user profile.
     *
     * @param {object} user
     * @returns {object}
     */
    static async getProfile(user) {
        return await supabase
            .from('profiles')
            .select(`username, avatar_url, is_first_login`)
            .eq('id', user.id)
            .single();
    }


    /**
     * This will set all skills to unselected aside from skillName.
     *
     * @param {object} user
     * @param {string} skillName
     * @returns {object}
     */
    static async setUnselectedSkills(user, skillName) {
        const { data, error } = await supabase
            .from('skills')
            .update({ selected: false })
            .eq('user_id', `${user.id}`)
            .neq('name', `${skillName}`);

        return { data, error };
    }
}