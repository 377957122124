import React from 'react';
import { useSelector } from 'react-redux';
import ActivityList from './ActivityList';

/**
 * RecentActivityTracker
 */
const RecentActivityTracker = (props) => {
    const recentActivities = useSelector(state => state.activities.recentActivities);

    return (
        <ActivityList
            header={'Latest Activity'}
            activities={recentActivities}
            link={'/activities/all'}
        />
    );
};

export default RecentActivityTracker;