import React, { useState, useEffect } from 'react';
import { supabase } from '../../../supabaseClient';
import { Link } from 'react-router-dom';
import InputGroup from '../../UI/forms/InputGroup';
import AuthForm from '../../forms/AuthForm';
import AuthPage from '../page-types/AuthPage';

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        setUrl(setUpUrl());
    }, []);

    function setUpUrl() {
        const hostName = window.location.hostname;

        if (hostName === 'localhost') {
            return 'http://localhost:3000/reset-password';
        } else {
            // TODO this must be changed for production
            return 'https://leveluptimetracker.com/reset-password';
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateFields() === false) {
            return;
        }

        handleReset(e);
    }

    function validateFields() {
        if (!email) {
            setError('Email is required');
            return false;
        }

        setError(null);
        return true;
    }

    async function handleReset(e) {
        setLoading(true);

        try {
            let { data, error } = await supabase.auth.resetPasswordForEmail(
                email,
                { redirectTo: url }
            );

            if (error) {
                throw error;
            } else {
                setSuccess(true);
                setError(null);
            }
        } catch (error) {
            console.error(error);
            setError(error.error_description || error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <AuthPage>
            <AuthForm
                title="Forgot Password"
                onSubmit={(e) => handleSubmit(e)}
                buttonText={loading ? <span>Loading...</span> : <span>Reset Password</span>}
                helpText={<p>Remember your password? <Link to='/sign-in'>Sign in here!</Link></p>}
                loading={loading}
                error={error}
                success={success}
                successText="Check your email for the password reset link!"
            >
                <InputGroup
                    type="email"
                    placeholder="email@email.com"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    label="Email"
                />
            </AuthForm>
        </AuthPage>
    )
}

export default ForgotPassword;