import React from 'react';
import getClassName from '../../utils/getClassName';

export function H1(props) {
    const className = getClassName(props, 'heading-1');

    return (
        <h1 className={className}>{props.children}</h1>
    );
}

export function H2(props) {
    const className = getClassName(props, 'heading-2');

    return (
        <h2 className={className}>{props.children}</h2>
    );
}

export function H3(props) {
    const className = getClassName(props, 'heading-3');

    return (
        <h3 className={className}>{props.children}</h3>
    );
}

export function H4(props) {
    const className = getClassName(props, 'heading-4');

    return (
        <h4 className={className}>{props.children}</h4>
    );
}

export function H5(props) {
    const className = getClassName(props, 'heading-5');

    return (
        <h5 className={className}>{props.children}</h5>
    );
}

export function H6(props) {
    const className = getClassName(props, 'heading-6');

    return (
        <h6 className={className}>{props.children}</h6>
    );
}

export function H7(props) {
    const className = getClassName(props, 'heading-7');

    return (
        <h6 className={className}>{props.children}</h6>
    );
}