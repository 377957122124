import React from 'react';
import AuthPage from '../page-types/AuthPage';
import { H2 } from '../../UI/headings';

function Sucess() {
    return (
        <AuthPage>
            <div className="form-container success-page">
                <H2 className="form-title">Success!</H2>
                <p>Check your email for the verification link to verify your account.</p>
            </div>
        </AuthPage>
    );
}

export default Sucess;