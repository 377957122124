import React, { useState, useEffect } from 'react';
import getClassName from '../../utils/getClassName';
import EditDots from '../../images/edit-dots.svg';

/**
 * Dropdown
 */
function Dropdown(props) {
    const [show, setShow] = useState(false);
    const isEditDropdown = props.type === 'edit';

    /**
     * This will hide the dropdown when the user clicks
     * outside of the dropdown.
     */
    useEffect(() => {
        if (!show) {
            return;
        }

        const app = document.querySelector('.App');
        app.addEventListener('click', () => {
            setShow(false);
        });

        return () => {
            app.removeEventListener('click', () => {
                setShow(false);
            });
        };
    }, [show]);

    /**
     * Setting classes for the dropdown.
     */
    const classes = getClassName(props, 'dropdown');

    let buttonClasses = 'dropdown-toggle ';
    buttonClasses += isEditDropdown ? 'edit-button' : 'button';

    let dropdownClasses = 'dropdown-menu ';
    if (!show) {
        dropdownClasses += ' hide';
    }

    /**
     * This will handle the 
     * callback for the dropdown options.
     * 
     * @param {function} customCallback 
     */
    function optionCallback(customCallback) {
        setShow(false);
        customCallback();
    }
    
    /**
     * Setting options for the dropdown.
     */
    const options = props.options.map((option, index) => {
        const customCallback = option.callback ?? props.optionCallback;
        return (
            <li 
                key={index}
                className="dropdown-item" 
                onClick={() => optionCallback(customCallback)}
            >
                {option.text}
            </li>
        );
    })

    return (
        <div className={classes}>
            <button 
                className={buttonClasses} 
                type="button"
                data-bs-toggle="dropdown" 
                aria-expanded={show}
                onClick={() => setShow(!show)}
            >
                {!isEditDropdown && props.title}
                {isEditDropdown && <img src={EditDots} alt="Edit button" />}
            </button>
            <ul className={dropdownClasses} aria-labelledby="dropdownMenuButton">
                {options}
            </ul>
        </div>
    );
}

export default Dropdown;