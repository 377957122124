import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../../supabaseClient';
import InputGroup from '../../UI/forms/InputGroup';
import AuthForm from '../../forms/AuthForm';
import AuthPage from '../page-types/AuthPage';

function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateFields() === false) {
            return;
        }

        handleReset(e);
    }

    function validateFields() {
        if (!password) {
            setError('Password is required');
            return false;
        }

        if (password !== passwordConfirmation) {
            setError('Passwords do not match');
            return false;
        }

        setError(null);
        return true;
    }

    async function handleReset(e) {
        setLoading(true);

        try {
            const { error } = await supabase.auth.updateUser(
                { 
                    password: password,
                    returning: 'minimal' // Don't return value after inserting
                }
            );

            if (error) {
                throw error;
            } else {
                setSuccess(true);
                setError(null);
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            setError(error.error_description || error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <AuthPage>
            <AuthForm 
                title="Reset Password"
                subTitle="Enter your new password below"
                onSubmit={(e) => handleSubmit(e)}
                buttonText={loading ? <span>Loading...</span> : <span>Reset Password</span>}
                loading={loading}
                error={error}
                success={success}
            >
                <InputGroup
                    type="password"
                    placeholder="Your new password"
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    label="Password"
                />
                <InputGroup
                    type="password"
                    placeholder="Confirm your new password"
                    required={true}
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    name="passwordConfirmation"
                    label="Confirm Password"
                />
            </AuthForm>
        </AuthPage>
    );
}

export default ResetPassword;