import React from 'react';
import { useSelector } from 'react-redux';
import { H5 } from '../UI/headings';

function ActivityCard(props) {
    const playAnimation = useSelector(state => state.activities.playAnimation);

    const classes = props.playActivityAnimation ? 'activity-card fade-in-delay-1s' : 'activity-card fade-in';

    return (
        <div className={'activity-card'} key={props.id}>
            <div className='activity-name'>
                <H5>{props.activity}</H5>
            </div>
            <div className='activity-time'>
                <p className='total text-2 bold'>{props.totalTime}</p>
                <p className='text-3'>{props.timeRange}</p>
            </div>
        </div>
    );
}

export default ActivityCard;