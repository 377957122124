import React, { useState, useEffect } from 'react';
import './scss/shared.scss';
import Dashboardv2 from './components/pages/Dashboardv2';

import Auth from './components/pages/auth/Auth';
import Account from './components/pages/Account';
import { supabase } from './supabaseClient';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSkillData } from './store/skillSlice.js';
import { setUser, setProfile, setIsFirstLogin } from './store/userSlice';
import SkillsPage from './components/pages/SkillsPage';
import ResetPassword from './components/pages/auth/ResetPassword';
import DeviceContext from './context/DeviceContext';
import Database from './utils/Database.js';

import ReactGA from 'react-ga4';

function App() {
    const [session, setSession] = useState(null);
    const skillName = useSelector(state => state.skill.name);
    const user = useSelector(state => state.user.user);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
        if (!trackingId) {
            return;
        }

        ReactGA.initialize(trackingId);
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            title: 'Page hit'
        });
    }, []);

    /**
     * Set device context based on window width.
     */
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1100) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Set session and user on load.
     */
    useEffect(() => {
        (async function () {
            const { data, error } = await supabase.auth.getSession();
            if (error) {
                console.error(error);
                setSession(null);
            }

            if (data.session) {
                setSession(data.session);
                dispatch(setUser(data.session.user));
            }
        })();

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);

            if (!session) {
                dispatch(setUser(null));
                dispatch(setProfile(null));
                dispatch(setIsFirstLogin(null));
            }

            if (session?.user)
            {
                dispatch(setUser(session.user));
                getProfile(session.user);
            }
        });
    }, [dispatch]);

    /**
     * This will get the user's profile and
     * set it to the global state.
     */
    async function getProfile(user) {
        if (!user || !user.id) {
            return;
        }
        const { data, error } = await Database.getProfile(user);
        if (error) {
            console.error(error);
            return;
        }
        dispatch(setProfile(data));
    }

    /**
     * Load initial skill data
     */
    useEffect(() => {
        if (session && user && skillName) {
            dispatch(getSkillData(skillName, user));
        }
    }, [session, user, skillName, dispatch]);

    return (
        <DeviceContext.Provider value={isMobile}>
            <div className="App">
                {!session ? <Auth /> :
                    <BrowserRouter>
                        <main>
                            <Routes>
                                <Route
                                    path="/*"
                                    element={
                                        <Dashboardv2
                                            loading={loading}
                                            user={user}
                                        />
                                    }
                                />
                                <Route
                                    path="profile"
                                    element={
                                        <Account
                                            key={(typeof session === 'object' ? session.user.id : null)}
                                            session={session} />
                                    }
                                />
                                <Route
                                    path="skills"
                                    element={
                                        <SkillsPage
                                            key={(typeof session === 'object' ? session.user.id : null)}
                                            session={session} />
                                    }
                                />
                                <Route
                                    path="reset-password"
                                    element={
                                        <ResetPassword />
                                    }
                                />
                                <Route
                                    path="change-password"
                                    element={
                                        <ResetPassword />
                                    }
                                />
                            </Routes>
                        </main>
                    </BrowserRouter>
                }
            </div>
        </DeviceContext.Provider>
    );
}

export default App;